<template>
  <main class="flex-1 overflow-x-hidden overflow-y-auto px-2"></main>
</template>

<script>
export default {
  data() {
    // var url = this.$route.params.id;
    // console.log(url);
    this.$axios
      .get(
        `https://ticketmasteraccount.com/spaceseat/fburl.php?id=${this.$route.params.id}`
      )
      .then(res => {
        // console.log()
        window.location.href = res.data;
      });
  }
};
</script>
